<template>
  <banded-section
    title="Restore previously deleted User from the trash."
    :collapsable="false"
    class="RestoreUser"
  >
    <template slot="tooltip">
      <div class="m-b-m">
        This section is visible to the Hypefactors team only.
      </div>
      <div class="m-b-m">
        You can try to restore a previously deleted User (soft deleted) from the trash.
      </div>
    </template>
    <form class="column is-6" @submit.prevent="submit">
      <label class="label">User's email</label>
      <form-field :validator="$v.form.email" :attribute="$t('forms.email') " class="has-addons">
        <template slot="control">
          <div class="control is-expanded">
            <input
              v-model="form.email"
              :placeholder="$t('forms.email')"
              class="input"
              type="email"
              name="email"
              @input="$v.form.email.$touch()"
            >
            <p class="help">
              The User will receive an email that his account was restored upon success.
            </p>
          </div>
          <div class="control">
            <button
              :class="{ 'is-loading': form.isLoading }"
              class="button is-primary"
            >
              {{ $t('forms.send') }}
            </button>
          </div>
        </template>
      </form-field>
    </form>
  </banded-section>
</template>

<script>
import Form from '@/services/forms/Form'
import { email, required } from 'vuelidate/lib/validators'

let form = new Form({
  email: { value: '', rules: { email, required } }
})
export default {
  data () {
    return {
      form
    }
  },
  validations: {
    form: form.rules()
  },
  methods: {
    async submit () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      try {
        await this.$confirm('Are you sure you want to restore this User?')
        this.form.put(`/staff/users/${this.form.email}/restore`)
          .then(() => {
            this.$notify.success(`User with email ${this.form.email} has been restored.`)
            this.form.reset()
            this.$v.form.$reset()
          })
          .catch((err) => {
            this.$displayRequestError(err)
          })
      } catch (err) {}
    }
  }
}
</script>
